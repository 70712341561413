import { ContentSlider, Video } from "@components/global";
import ColumnContent from "@components/global/columnContent";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import ProductPreview from "@components/global/productPreview";
import RecipePreview from "@components/global/recipePreview";
import ProductHero from "@components/product/productHero";
import { Query, SanityMetaImage } from "@graphql-types";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import {
  isSanityColumnContent,
  isSanityContentSlider,
  isSanityLearnCategoryPreview,
  isSanityProductPreview,
  isSanityRecipePreview,
  isSanityVideo,
} from "@util/types";
import { PageProps, graphql } from "gatsby";
import React from "react";

interface Props extends PageProps {
  data: Query;
}

function ProductInnerTemplate({ data }: Props) {
  const product = data.allSanityProduct.nodes[0];

  return (
    <>
      {product.seo && <SEO seoData={product.seo} slug={`/products/${product.slug?.current}`} />}
      <Layout>
        <ProductHero data={product} />
        <div>
          {product.pageContent &&
            product.pageContent.map(data => {
              if (data == null) {
                return null;
              }

              if (isSanityColumnContent(data)) {
                return <ColumnContent data={data} key={data._key} />;
              }
              if (isSanityContentSlider(data)) {
                return <ContentSlider data={data} key={data._key} />;
              }
              if (isSanityRecipePreview(data)) {
                return <RecipePreview data={data} key={data._key} />;
              }
              if (isSanityLearnCategoryPreview(data)) {
                return <LearnCategoryPreview data={data} key={data._key} />;
              }

              if (isSanityProductPreview(data)) {
                return <ProductPreview data={data} key={data._key} />;
              }
              if (isSanityVideo(data)) {
                return (
                  <Container
                    position="relative"
                    height="550px"
                    tabletHeight="400px"
                    width="100%"
                    margin="15px auto"
                    tabletMargin="80px auto"
                    zIndex={4}
                    key={data._key}
                  >
                    <Video
                      url={data.url as string}
                      id={data.url as string}
                      useThumbnail
                      thumbnail={data.image as SanityMetaImage}
                      controls
                      volume
                    />
                  </Container>
                );
              }
              return null;
            })}
        </div>
      </Layout>
    </>
  );
}

export default ProductInnerTemplate;

export const query = graphql`
  query allProductQuery($slug: String) {
    allSanityProduct(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityProduct
      }
    }
  }
`;
